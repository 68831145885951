<template>
	<div class="list2">
		<div v-if="listData && listData.length>0" class="list">
			<div class="list_list">
				<div v-for="(item, index) in listData" :key="index" class="list_item">
					<div class="list_div" @click="goItem(item)">
						<div class="list_pic" :style="'background-image: url('+item.listImg+');'"></div>
						<div class="list_title">{{item.listTitle}}</div>
						<div class="list_desc">{{item.listDesc}}</div>
					</div>
				</div>
			</div>
			<template v-if="from && from=='list'">
				<div class="pagination only_pc">
					<el-pagination @current-change="changePage" background layout="prev, pager, next" :page-size.sync="pageSize" :current-page.sync="page" :total="total"></el-pagination>
				</div>
				<div class="pagination only_mob">
					<el-pagination @current-change="changePage" small background layout="prev, pager, next" :page-size.sync="pageSize" :current-page.sync="page" :total="total"></el-pagination>
				</div>
			</template>
		</div>
		<div v-else-if="!loading" class="list_empty">
			<span>暂无数据</span>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'List',
		props: ['data', 'pageNum', 'pageSize', 'total', 'loading', 'from'],
		data() {
			var listData=this.data || []
			listData.forEach(item=>{
				if(!item.listImg || item.listImg.length<=0)item.listImg='/images/empty_image.png'
			})
			return {
				page: this.pageNum || 1,
				listData: listData
			}
		},
		methods: {
			goItem(item) {
				if(this.from && this.from == 'list') {
					item.listLink = '/page/' + item.id
				}
				if(item.listLink && item.listLink.length > 0) {
					this.$router.push({
						path: item.listLink
					})
				}
			},
			changePage(num) {
				this.$parent.changePage(num)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.list2 {
		@include body_block;
		min-height: 100px;
		.list {
			.list_list {
				margin-left: -10px;
				margin-right: -10px;
				&:after {
					display: block;
					content: '';
					clear: both;
				}
				@media (max-width: $mob-screen) {
					margin-left: -4px;
					margin-right: -4px;
				}
				.list_item {
					float: left;
					width: 20%;
					padding: 8px 10px;
					&:first-child {
						width: 40%;
						.list_div {
							.list_pic {
								height: 429px;
							}
						}
						@media (max-width: 1200px) {
							width: 50%;
						}
						@media (max-width: 900px) {
							width: 66%;
						}
						@media (max-width: $mob-screen) {
							width: 100%;
							padding: 4px;
							.list_div {
								.list_pic {
									height: 200px;
								}
							}
						}
					}
					.list_div {
						cursor: pointer;
						.list_pic {
							@include pic_bg_cover;
							height: 176px;
							border-radius: 6px 6px 0 0;
							@media (max-width: $mob-screen) {
								height: 100px;
							}
						}
						.list_title {
							@include single_line_ellipsis;
							margin-top: 3px;
							line-height: 27px;
							height: 27px;
							font-size: 22px;
							@media (max-width: $mob-screen) {
								line-height: 24px;
								height: 24px;
								font-size: 16px;
							}
						}
						.list_desc {
							@include single_line_ellipsis;
							margin-top: 7px;
							line-height: 24px;
							height: 24px;
							font-size: 20px;
							color: #7a7a7a;
							@media (max-width: $mob-screen) {
								margin-top: 0;
								line-height: 20px;
								height: 20px;
								font-size: 14px;
							}
						}
					}
					@media (max-width: 1200px) {
						width: 25%;
					}
					@media (max-width: 900px) {
						width: 33%;
					}
					@media (max-width: $mob-screen) {
						width: 50%;
						padding: 4px;
					}
				}
			}
			.pagination {
				margin-top: 16px;
				text-align: right;
				@media (max-width: $mob-screen) {
					text-align: center;
				}
			}
			.only_pc {
				display: block;
				@media (max-width: $mob-screen) {
					display: none;
				}
			}
			.only_mob {
				display: none;
				@media (max-width: $mob-screen) {
					display: block;
				}
			}
		}
		.list_empty {
			color: #ccc;
			font-size: 18px;
			@media (max-width: $mob-screen) {
				font-size: 14px;
			}
		}
	}
</style>